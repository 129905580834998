import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CategoryScale,
    Chart as ChartJS,
    Chart,
    ChartDataset,
    ChartEvent,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import moment from 'moment';

import {
    chartBox,
    container,
    errorText,
    loader,
    loading,
    tooltip,
    tooltipAddImage,
    tooltipButton,
    tooltipDataContainer,
    tooltipDate,
    tooltipDateIcon,
    tooltipImage,
    tooltipImageButton,
    tooltipImageContainer,
    tooltipValue,
    emptyContainer,
    emptyImg,
    emptyBox,
    emptyTitle,
    emptyContent,
    button,
} from './transition-measurements.module.scss';
import imageEmpty from '../../../assets/images/measurement-chart.png';
import CalendarIcon from '../../../assets/images/svg/callendar.svg';
import { TStatus } from '../../../models/status.model';
import { ITransitionMeasurement } from '../../../models/transition.model';
import { GenericListFilters } from '../../../models/generic-list.model';
import {
    selectTransitionMeasurementCount,
    selectTransitionMeasurementCountStatus,
    selectTransitionMeasurements,
    selectTransitionMeasurementsStatus,
} from '../../../redux/transition/transition.selectors';
import {
    clearTransitionMeasurementCount,
    clearTransitionMeasurements,
    getTransitionMeasurementCount,
    getTransitionMeasurements,
} from '../../../redux/transition/transition.actions';
import { addModalAction } from '../../../redux/actions/actions-modals';
import { getParamsFromFilters } from '../../../utills/filter-utils';
import { getFormattedDate } from '../../../utills/date-utils';
import { getUserTokenData } from '../../../utills/get-user-token-data';

import Loader from '../../atoms/loader';
import TransitionChartHeader, {
    ITransitionChartHeaderProps,
} from '../../molecules/transition-chart-header';
import Button from '../../atoms/button';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    TimeScale
);

type TMeasurementsType = 'weight' | 'waist' | 'thigh';

interface ITransitionMeasurementsProps {
    className?: string;
}

interface ITransitionMeasurementsState {
    filters: GenericListFilters | null;
    items: ITransitionMeasurement[];
}

interface ITransitionMeasurementCount {
    count: number | null;
    countWithPhotos: number | null;
}

const measurementsTypes: TMeasurementsType[] = ['weight', 'waist', 'thigh'];

const TransitionMeasurements: React.FC<ITransitionMeasurementsProps> = ({ className = '' }) => {
    const dispatch = useDispatch();
    const measurements: ITransitionMeasurementsState = useSelector(selectTransitionMeasurements);
    const measurementCount: ITransitionMeasurementCount = useSelector(
        selectTransitionMeasurementCount
    );
    const status: TStatus | undefined = useSelector(selectTransitionMeasurementsStatus);
    const countStatus = useSelector(selectTransitionMeasurementCountStatus);
    const chartBoxRef = useRef<HTMLDivElement | null>(null);
    const [chartContainerStyle, setChartContainerStyle] = useState<React.CSSProperties>({
        position: 'relative',
    });

    const chartRange = getChartRange(measurements);
    const datasets = measurementsTypes.map((type) => getChartDataset(measurements.items, type));

    const showLoader =
        !status ||
        status === 'idle' ||
        (status === 'loading' && !measurements.items.length) ||
        !countStatus ||
        countStatus === 'idle' ||
        countStatus === 'loading';
    const hasMeasurements = measurementCount.count === null || measurementCount.count > 0;

    const handleAddMeasurement = () => {
        dispatch(
            addModalAction({
                modalKey: 'MEASUREMENT_FORM_MODAL',
            })
        );
    };

    const handleFilterChange: ITransitionChartHeaderProps['onFiltersChange'] = (filters) => {
        const params = getParamsFromFilters(filters);
        dispatch(getTransitionMeasurements(params));
    };

    const handleClear = () => {
        dispatch(getTransitionMeasurements());
    };

    const handleShowMore = (measurement: ITransitionMeasurement) => {
        dispatch(
            addModalAction({
                modalKey: 'TRANSITION_GALLERY_MODAL',
                modalProps: {
                    measurement: measurement,
                },
            })
        );
    };

    const handleAddImage = (measurement: ITransitionMeasurement) => {
        dispatch(
            addModalAction({
                modalKey: 'MEASUREMENT_FORM_MODAL',
                modalProps: {
                    measurement: measurement,
                },
            })
        );
    };

    useEffect(() => {
        dispatch(getTransitionMeasurementCount());
        return () => {
            dispatch(clearTransitionMeasurementCount());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(getTransitionMeasurements({ filters: 'default' }));
        return () => {
            dispatch(clearTransitionMeasurements());
        };
    }, [dispatch]);

    useEffect(() => {
        if (status !== 'success') return;
        const handleResize = () => {
            if (!chartBoxRef.current) return;
            setChartContainerStyle((prev) => {
                if (!chartBoxRef.current) return prev;
                return {
                    ...prev,
                    width: chartBoxRef.current.clientWidth,
                    height: chartBoxRef.current.clientHeight,
                };
            });
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [status]);

    if (showLoader) {
        return (
            <div className={`${container} ${className}`}>
                <Loader className={loader} />
            </div>
        );
    }

    if (status === 'fail') {
        return (
            <div className={`${container} ${className}`}>
                <p className={errorText}>{copy.error}</p>
            </div>
        );
    }

    return (
        <div className={`${container} ${className} ${status === 'loading' ? loading : ''}`}>
            <TransitionChartHeader
                filters={hasMeasurements ? measurements.filters : null}
                title={copy.title}
                subtitle={copy.subtitle}
                buttonText={copy.button}
                onAdd={hasMeasurements ? handleAddMeasurement : undefined}
                onFiltersChange={handleFilterChange}
                onClearFilters={handleClear}
            />
            {!hasMeasurements && (
                <div className={emptyContainer}>
                    <img src={imageEmpty} alt="" className={emptyImg} />
                    <div className={emptyBox}>
                        <h3 className={emptyTitle}>{copy.emptyTitle}</h3>
                        <p className={emptyContent}>{copy.emptyContent}</p>
                        <Button
                            className={button}
                            size="small"
                            color="yellow"
                            onClick={handleAddMeasurement}
                        >
                            {copy.button}
                        </Button>
                    </div>
                </div>
            )}
            {hasMeasurements && (
                <div className={chartBox} ref={chartBoxRef}>
                    <div className="chart-container" style={chartContainerStyle}>
                        <Line
                            plugins={[
                                {
                                    id: 'chartAreaBorder',
                                    beforeDraw(chart) {
                                        const {
                                            ctx,
                                            chartArea: { left, top, width, height },
                                        } = chart;
                                        ctx.save();

                                        ctx.strokeStyle = 'rgba(239, 239, 239, 1)';
                                        ctx.beginPath();
                                        ctx.moveTo(left + 10, top);
                                        ctx.arcTo(
                                            left + width,
                                            top,
                                            left + width,
                                            top + height,
                                            10
                                        );
                                        ctx.arcTo(
                                            left + width,
                                            top + height,
                                            left,
                                            top + height,
                                            10
                                        );
                                        ctx.arcTo(left, top + height, left, top, 10);
                                        ctx.arcTo(left, top, left + width, top, 10);

                                        ctx.stroke();
                                        ctx.clip();
                                        ctx.restore();
                                    },
                                },
                                {
                                    id: 'backgroundStripes',
                                    beforeDraw(chart) {
                                        const {
                                            ctx,
                                            chartArea: { left, top, width, height },
                                        } = chart;
                                        ctx.save();

                                        const colors = [
                                            'rgba(239, 239, 239, 0.29)',
                                            'rgba(255,255,255,1)',
                                        ];
                                        const stripeWidth = width / 4;

                                        for (let i = 0; i < 4; i++) {
                                            ctx.fillStyle = colors[i % 2];
                                            ctx.fillRect(
                                                left + i * stripeWidth,
                                                top,
                                                stripeWidth,
                                                height
                                            );
                                        }

                                        ctx.restore();
                                    },
                                },
                            ]}
                            data={{ datasets }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        type: 'time',
                                        min: chartRange[0],
                                        max: chartRange[1],
                                        ticks: {
                                            padding: 20,
                                        },
                                        grid: {
                                            display: false,
                                        },
                                        border: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        ticks: {
                                            maxTicksLimit: 5,
                                        },
                                        grid: {
                                            lineWidth: function (context) {
                                                return context.index === 0 ||
                                                    context.index ===
                                                        context.chart.scales['y'].ticks.length - 1
                                                    ? 0
                                                    : 1;
                                            },
                                        },
                                        border: {
                                            dash: [4, 5],
                                            display: false,
                                        },
                                    },
                                },
                                plugins: {
                                    legend: {
                                        position: 'bottom',
                                        align: 'start',
                                        labels: {
                                            usePointStyle: true,
                                            padding: 20,
                                        },
                                    },
                                    tooltip: {
                                        enabled: false,
                                    },
                                    filler: {
                                        propagate: false,
                                    },
                                },
                                onClick: (event, chartElement, chart) => {
                                    if (chartElement.length > 0) {
                                        const datasetIndex = chartElement[0].datasetIndex;
                                        const index = chartElement[0].index;
                                        const label = datasets[datasetIndex].label || '';
                                        const value = datasets[datasetIndex].data[index];
                                        const pointColor =
                                            datasets[datasetIndex].pointBackgroundColor || '';
                                        const measurement = getMeasurementByTimestamp(
                                            value.x,
                                            measurements.items
                                        );

                                        showCustomTooltip({
                                            chart,
                                            event,
                                            label,
                                            value,
                                            color: pointColor as string,
                                            measurement,
                                            handleShowMore,
                                            handleAddImage,
                                        });
                                    } else {
                                        hideCustomTooltip();
                                    }
                                },
                                elements: {
                                    line: {
                                        tension: 0.4,
                                    },
                                },
                            }}
                        />
                        <div id={'tooltip'} className={tooltip}>
                            <div id={'tooltip-data-container'} className={tooltipDataContainer}>
                                <p className={tooltipDate}>
                                    <CalendarIcon className={tooltipDateIcon} />
                                    <span id={'tooltip-date'} />
                                </p>
                                <p id={'tooltip-value'} className={tooltipValue} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

interface IShowCustomTooltipConfig {
    chart: Chart;
    event: ChartEvent;
    label: string;
    value: { x: number; y: number };
    color: string;
    measurement: ITransitionMeasurement | undefined;
    handleShowMore(measurement: ITransitionMeasurement): void;
    handleAddImage(measurement: ITransitionMeasurement): void;
}

async function showCustomTooltip({
    chart,
    event,
    label,
    value,
    color,
    measurement,
    handleShowMore,
    handleAddImage,
}: IShowCustomTooltipConfig) {
    const tooltipElement = document.getElementById('tooltip');
    const tooltipDataContainerElement = document.getElementById('tooltip-data-container');
    const tooltipDateElement = document.getElementById('tooltip-date');
    const tooltipValueElement = document.getElementById('tooltip-value');
    const chartArea = chart.chartArea;

    if (!tooltipElement) return null;

    if (tooltipDateElement) {
        const day = new Intl.DateTimeFormat('pl', { day: '2-digit' }).format(value.x);
        const month = new Intl.DateTimeFormat('pl', { month: 'long' }).format(value.x);
        const year = new Intl.DateTimeFormat('pl', { year: 'numeric' }).format(value.x);

        tooltipDateElement.innerHTML = `${day} ${month} ${year}`;
    }

    if (tooltipValueElement) {
        tooltipValueElement.innerHTML = `<span class="dot" style="background: ${color};"></span> ${label} <span>${value.y} kg</span>`;
    }

    if (tooltipDataContainerElement) {
        document.getElementById('tooltip-image-container')?.remove();
        document.getElementById('tooltip-button')?.remove();

        if (measurement && measurement.photos.length > 0) {
            const container = document.createElement('div');
            container.classList.add(`${tooltipImageContainer}`);
            container.id = 'tooltip-image-container';

            const img = document.createElement('img');
            img.classList.add(`${tooltipImage}`);
            const tokenData = getUserTokenData();
            const token = tokenData?.token;
            const headers = new Headers();
            headers.set('Authorization', `Bearer ${token}`);
            const src = measurement.photos[0].uri || (measurement.photos[0].url as string);
            const response = await fetch(src, { headers });
            const blob = await response.blob();
            img.src = URL.createObjectURL(blob);
            container.append(img);

            const imageButton = document.createElement('button');
            imageButton.classList.add(`${tooltipImageButton}`);
            imageButton.textContent = 'Zobacz więcej';
            imageButton.setAttribute('data-value', `${value.x}`);
            if (measurement) {
                imageButton.addEventListener('click', () => handleShowMore(measurement));
                hideCustomTooltip();
            }
            container.append(imageButton);

            tooltipElement.prepend(container);

            const button = document.createElement('button');
            button.classList.add(`${tooltipButton}`);
            button.id = 'tooltip-button';
            button.textContent = 'Kliknij i zobacz więcej';
            button.setAttribute('data-value', `${value.x}`);
            if (measurement) {
                button.addEventListener('click', () => handleShowMore(measurement));
                hideCustomTooltip();
            }
            tooltipDataContainerElement.append(button);
        } else {
            const container = document.createElement('div');
            container.classList.add(`${tooltipImageContainer}`);
            container.id = 'tooltip-image-container';

            const button = document.createElement('button');
            button.classList.add(`${tooltipAddImage}`);
            button.textContent = 'Dodaj zdjęcia';
            button.setAttribute('data-value', `${value.x}`);
            if (measurement) {
                button.addEventListener('click', () => handleAddImage(measurement));
                hideCustomTooltip();
            }
            container.append(button);
            tooltipElement.prepend(container);
        }
    }

    const tooltipRight = event.x ? getTooltipRight(event.x, chartArea.width) : event.x;
    const tooltipTop = event.y ? getTooltipTop(event.y, chartArea.height) : event.y;

    tooltipElement.style.opacity = '1';
    tooltipElement.style.pointerEvents = 'auto';
    tooltipElement.style.left = tooltipRight + 'px';
    tooltipElement.style.top = tooltipTop + 'px';
}

function hideCustomTooltip() {
    const tooltipElement = document.getElementById('tooltip');
    if (tooltipElement) {
        tooltipElement.style.opacity = '0';
        tooltipElement.style.pointerEvents = 'none';
    }
}

function getTooltipTop(y: number, chartHeight: number) {
    if (y < 125) {
        return y + (125 - y);
    } else if (y > chartHeight) {
        return y - (y - chartHeight);
    } else {
        return y;
    }
}

function getTooltipRight(x: number, chartWidth: number) {
    if (x + 150 > chartWidth) {
        return x - 150;
    } else {
        return x;
    }
}

function getChartRange(measurements: ITransitionMeasurementsState) {
    if (measurements.filters) {
        const appliedYear = Object.values(measurements.filters.rok.values).find(
            (value) => value.applied
        )?.value;
        const appliedMonth = Object.values(measurements.filters.miesiac.values).find(
            (value) => value.applied
        )?.value;
        if (!appliedMonth && appliedYear) {
            return [`${appliedYear}-01-01`, `${appliedYear}-12-31`];
        }
        if (appliedMonth && appliedYear) {
            return [
                `${appliedYear}-${appliedMonth}-01`,
                `${appliedYear}-${appliedMonth}-${moment(`${appliedYear}-${appliedMonth}-01`)
                    .endOf('month')
                    .format('DD')}`,
            ];
        }
    }
    if (measurements.items.length) {
        const minDate = new Date(
            Math.min(...measurements.items.map((item) => new Date(item.date).getTime()))
        );
        const maxDate = new Date(
            Math.max(...measurements.items.map((item) => new Date(item.date).getTime()))
        );
        return [
            `${minDate.getFullYear()}-${minDate.getMonth() + 1}-${minDate.getDate()}`,
            `${maxDate.getFullYear()}-${maxDate.getMonth() + 1}-${maxDate.getDate()}`,
        ];
    }
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    return [
        `${year}-${month}-1`,
        `${year}-${month}-${moment(`${year}-${month}-01`).endOf('month').format('DD')}`,
    ];
}

const copy = {
    title: 'Pomiary',
    subtitle: 'Dodawaj zdjęcia i pomiary, aby śledzić swój progres.',
    error: 'Nie udało się pobrać danych pomiarów. Spróbuj ponownie później...',
    button: 'Dodaj pomiar',
    clear: 'Pełny zakres',
    weight: 'Waga (kg)',
    waist: 'Obwód pas (cm)',
    thigh: 'Obwód udo (cm)',
    emptyTitle: 'Właśnie tak może wyglądać Twój wykres postępu!',
    emptyContent:
        'Wypełnijaj ankietę i aktualizuj dane, aby zobaczyć swoją drogę do celu w pełnej odsłonie.',
};

const colors = {
    weight: '115, 89, 217',
    waist: '239, 64, 122',
    thigh: '5, 222, 163',
};

function getMeasurementByTimestamp(timestamp: number, measurements: ITransitionMeasurement[]) {
    const unixTimestamp = timestamp / 1000;
    const date = getFormattedDate(unixTimestamp, false, true);
    return measurements.find((measurement) => measurement.date === date);
}

function getChartDataset(
    measurements: ITransitionMeasurement[],
    type: TMeasurementsType
): ChartDataset<'line', { x: number; y: number }[]> {
    const data = measurements
        .filter((measurement) => measurement[type] !== null)
        .map((measurement) => {
            return {
                x: new Date(measurement.date).getTime(),
                y: measurement[type] as number,
            };
        });
    return {
        label: copy[type],
        data,
        borderColor: `rgba(${colors[type]}, 1.0)`,
        pointBackgroundColor: `rgba(${colors[type]}, 1.0)`,
        pointBorderWidth: 3,
        borderWidth: 2,
        backgroundColor: (context: any) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (chartArea) {
                const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                gradient.addColorStop(0, `rgba(${colors[type]}, 1)`);
                gradient.addColorStop(0.6, `rgba(${colors[type]}, 0)`);
                return gradient;
            }
            return '';
        },
        fill: true,
    };
}

export default TransitionMeasurements;
